import React from "react"; // ,{ useState }
import "./sidebar.scss";
import { Link, NavLink } from "react-router-dom";

import {
  EnvelopeIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  Squares2X2Icon,
  // Squares2X2Icon,
  // Cog6ToothIcon,
  // UserIcon,
  // ArrowDownIcon,
  // ArrowUpIcon,
  // DocumentTextIcon,
  // CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import { Button } from "../Button";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../modules";
import { DepositIcon, HistoryIcon, SettingsIcon, WalletIcon, WithdrawIcon } from "../../assets/icons";
import { ExchangeIcon } from "../../assets/icons/Exchange";
export const Sidebar = () => {
  const toggleSidebar = () => {
    const sidebar = document.getElementsByClassName("sidebar-wrapper");
    sidebar[0].classList.toggle("show");
  };
  const User = useSelector(selectUserInfo);

  return (
    <>
      <div className="sidebar-wrapper" onMouseLeave={toggleSidebar}>

        <div className="sidebar-content">
          {
            User.email && <>



              <div className="sidebar-item">
                <NavLink to="/wallet">
                  <WalletIcon className="i24 svg-solid" />
                  Wallet
                </NavLink>
              </div>


              <div className="sidebar-item">
                <NavLink to="/exchange">
                  <ExchangeIcon className="i24 svg-solid" />
                  Swap
                </NavLink>
              </div>

              <div className="sidebar-item">
                <NavLink to="/deposit-histories">
                  <DepositIcon className="i24 svg-solid" />
                  Deposit Histories
                </NavLink>
              </div>

              <div className="sidebar-item">
                <NavLink to="/withdraw-histories">
                  <WithdrawIcon className="i24 svg-solid" />
                  Withdraw Histories
                </NavLink>
              </div>

              <div className="sidebar-item">
                <NavLink to="/swap-histories">
                  <HistoryIcon className="i24 svg-solid" />
                  Swap Histories
                </NavLink>
              </div>



              <div className="sidebar-item">
                <NavLink to="/profile">
                  <SettingsIcon className="i24 svg-solid" />
                  Settings
                </NavLink>
              </div>
              <hr />

            </>}

          <div className="sidebar-item">
            <a href="https://usvibitcoin.com" target="_blank" rel="noopener noreferrer">
              <GlobeAltIcon className="i24" />
              Website
            </a>
          </div>
          <div className="sidebar-item">
            <a href="https://explorer.usvibitcoin.com" target="_blank" rel="noopener noreferrer">
              <Squares2X2Icon className="i24" />
              Block Explorer
            </a>
          </div>
          <div className="sidebar-item">
            <a href="https://usvibitcoin.com/#about" target="_blank" rel="noopener noreferrer" >
              <InformationCircleIcon className="i24" />
              About
            </a>
          </div>
          <div className="sidebar-item">
            <a href="https://t.me/USVirginIslandsBitcoin" target="_blank" rel="noopener noreferrer">
              <EnvelopeIcon className="i24" />
              Contact
            </a>
          </div>
        </div>


        {!User.email && (
          <div className="bottom">
            <Link to="/login">
              <Button
                size="medium"
                lable="Login"
                type="primary"
                className="outline x100"
              />
            </Link>
            <Link to="/signup">
              <Button
                size="medium"
                lable="Register"
                type="primary"
                className="x100"
              />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
